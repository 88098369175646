import React from "react"
import Seo from "./Seo";
import {getImage, StaticImage} from "gatsby-plugin-image";
import Icon from "./presentation/Icon";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import Layout from "./Layout";
import {graphql, Link, useStaticQuery} from "gatsby";
import {BgImage} from "gbimage-bridge";
import Header from "./Header";

function TextPage({heading, children, slim, thumbnail}){

    const title = heading

    const isSlim = !!slim

    const { placeholderImage } = useStaticQuery(
        graphql`
          query {
            placeholderImage: file(relativePath: { eq: "MunichBackground-colored-f0f0f0.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        `
    );

    const image = getImage(placeholderImage)

    return (
        <Layout>
            <Seo title={title} thumbnail={thumbnail}/>
            <BgImage
                image={image}
                Tag="div"
                style={{
                    padding: "2rem 0 45% 0",
                    backgroundPosition: "bottom",
                    backgroundSize: "contain",
                    backgroundColor: "#f0f0f0"
                }}
                alt="Legal Software Effect"
            >
                <Header center showText/>
                <div className="container" style={{marginTop: "4rem"}}>
                    <div className="row" >
                        <div className={
                            isSlim ? (
                                "col-lg-6 col-lg-offset-3 col-sm-8 col-sm-offset-2 col-12"
                            ) : (
                                "col-sm-8 col-sm-offset-2 col-12"
                            )
                        }>
                            <div className="b3">
                                <h1 className="b3" style={{
                                    display: "inline",
                                    lineHeight: "1.75",
                                    borderBottom: "solid 7px #039BE5"
                                }}>
                                    {heading}
                                </h1>
                            </div>
                            {children}
                        </div>
                    </div>
                </div>
            </BgImage>

        </Layout>)

}

export default TextPage