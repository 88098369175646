import React from "react"
import {StaticImage} from "gatsby-plugin-image";
import Link from "gatsby-link";


function ProfileMax() {

    return (
        <Link to="/about#max">
        <div style={{
            display: "flex",
            alignItems: "center"
        }}>
            <div style={{
                display: "inline-block",
                width: 60,
                height: 60,
                overflow: "hidden",
                borderRadius: 10,
                boxShadow: "2px 2px 5px 2px rgba(0,0,0,0.2)"
            }}>
                <StaticImage
                    layout="fixed"
                    src="../images/max_profile.jpg"
                    width={60}
                    height={60}
                    imgStyle={{borderRadius: 10}}
                    placeholder="blurred"
                    alt="Max Pschiebel"
                />
            </div>
            <div style={{margin: "1.0rem"}}>
                <div className="bold">Max Pschiebel</div>
                <div className="light">CEO</div>
            </div>
        </div>
        </Link>
    )
}

function Profile({name}){

    if(name.toLowerCase().startsWith("max")){
        return (<ProfileMax />)
    }

    return null
}

export default Profile