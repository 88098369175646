import * as React from "react"
import { Link, graphql } from "gatsby"

import Seo from "../components/Seo"
import Layout from "../components/Layout";
import TextPage from "../components/TextPage";
import {faCalendarAlt, faUser} from "@fortawesome/free-solid-svg-icons";
import Icon from "../components/presentation/Icon";
import Profile from "../components/Profile";

const Blog = ({ data, location }) => {
    const posts = data.allMarkdownRemark.nodes

    if (posts.length === 0) {
        throw "No blog posts found"
    }

    return (
        <TextPage heading="Unser Blog">

            <ol style={{ listStyle: `none` }}>
                {posts.map(post => {
                    const title = post.frontmatter.title
                    const subtitle = post.frontmatter.subtitle
                    const date = post.frontmatter.date
                    const author = post.frontmatter.author

                    return (
                        <li key={post.fields.slug} className="b3">
                            <Link to={post.fields.slug} itemProp="url">
                            <article
                                className="post-list-item card"
                                itemScope
                                itemType="http://schema.org/Article"
                            >
                                <header>
                                    <h3 style={{ fontSize: "1.25em" }} className="b5">{title}</h3>
                                    <h5 style={{ fontSize: "1.0em" }} className="b5">
                                        {subtitle}
                                    </h5>
                                    <small>{date}</small>
                                </header>
                            </article>
                            </Link>
                        </li>
                    )
                })}
            </ol>
        </TextPage>
    )
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD. MMMM YYYY")
          title
          subtitle
          description
          author
        }
      }
    }
  }
`
